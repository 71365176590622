<template>
  <div class="col-12 col-lg-9 d-none d-lg-block">
          <div class="">
            <img class="" style='width:7vw;padding:3px;height: 7vw;' src='/img/hbscmoney.png' alt='icon'>
            <span class="black">HBSC</span>
            <span class="orange"> Bonus</span>
            <span class="orange"> for</span>
            <span class="black"> hxy.business </span>
            <span class="orange">investors (1 of 4)</span>
          </div>
          
          <div class="" style='    margin-left: 7vw;font-size:1vw;
                margin-top: -21px;
            '>Claim your hxy business investors' HBSC bonus.
          </div>
          </div>
</template>

<script>
export default {

}
</script>

<style>

</style>